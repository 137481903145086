import React from 'react'
import styled from 'styled-components'
import { colors, media } from 'styles'

const Button = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  color: #FFF;
  font-size: 45px;
  background: ${colors.PRIMARY};
  cursor: pointer;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    top: 16%;
    left: 10%;
    border-radius: 100%;
    background: ${colors.PRIMARY};
    filter: blur(8px);
    z-index: -1;
  }
`

const ButtonTopRight = styled(Button)`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;

  ${media.tablet`
    top: -10px;
    right: -45px;
  `}

  ${media.mobile`
    right: -25px;
  `}
`

export const BigCloseButtonTopRight = (props) => (
  <ButtonTopRight {...props}><i className="icon-close" /></ButtonTopRight>
)

export default (props) => (
  <Button {...props}><i className="icon-close" /></Button>
)
