import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { colors, media } from 'styles'

const base = css`
  display: inline-block;
  position: relative;
  background: ${colors.SECONDARY};
  color: ${colors.WHITE};
  padding: 22px 44px;
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  z-index: 0;

  ${props => props.block && `
    display: block;
    width: 100%;
  `}

  ${props => props.disabled && `
    opacity: 0.3;
    cursor: default;
  `}

  &:before {
    content: '';
    position: absolute;
    left: 16px;
    right: 16px;
    top: 11px;
    bottom: 2px;
    background: ${colors.SECONDARY};
    filter: blur(7px);
    z-index: -1;
  }

  ${media.tablet`
    padding: 19px 38px;
  `}
`

export const BaseButton = styled.button`
  ${base}
`

export const InternalLink = styled(Link)`
  ${base}
  text-decoration: none;
`

export const ExternalLink = styled.a`
  ${base}
  text-decoration: none;
`

export const Inner = styled.span`
  position: relative;

  ${media.desktop`
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: -2px;
      border-bottom: 1px solid #FFF;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      right: -2px;
      border: 2px solid transparent;
      border-top-color: #FFF;
      border-left-color: #FFF;
    }
  `}
`
