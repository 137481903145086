import React, { Component } from 'react'
import { navigate } from 'gatsby'
import PageLayout from 'components/PageLayout'
import SEO from 'components/seo'
import Button from 'components/Button'
import { Input, Select } from 'components/Form'
import {
  Wrapper,
  CloseButton,
  Card,
  Top,
  Content,
  FormWrapper,
  Row,
  Col,
  BedroomGroup,
  BedroomItem,
  BedroomInputLabel,
  Message,
  MessageBody
} from './styles'
import { isEmpty, isNil, pathOr, mergeRight, keys, join } from 'ramda'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { getTexts } from '../../utilities/translations'

const t = getTexts({
  'en': {
    heading1: `Want to know more?`,
    prefix1: `All fields are required.`,
    subheading1: `Tell us about you`,
    subheading2: `Property interest`,
    titleFieldLabel: `Title`,
    titleOptions: [
      { label: 'Mr', value: 'Mr' },
      { label: 'Mrs', value: 'Mrs' },
      { label: 'Miss', value: 'Miss' },
      { label: 'Ms', value: 'Ms' },
      { label: 'Dr', value: 'Dr' }
    ],
    nameFieldLabel: `Name`,
    surnameFieldLabel: `Surname`,
    emailAddressFieldLabel: `Email address`,
    phoneFieldLabel: `Contact number`,
    countryFieldLabel: `Location of residence`,
    propertyValueFieldLabel: `Property value`,
    bedroomsFieldLabel: `No. of bedrooms`,
    bedroomOptSuite: `suite`,
    bedroomOpt1Bed: `1 bed`,
    bedroomOpt2Bed: `2 bed`,
    bedroomOpt3Bed: `3 bed`,
    bedroomOptPenthouse: `penthouse`,
    submitLabel: `Submit`,
    successTitle: `Success!`,
    successMessage: `Thanks for registering your interest, we'll be in touch soon.`,
    errorTitle: `Ouch!`,
    errorMessage: `Sorry there's been an error, please try again.`,
    valueOpt1: '<£700K',
    valueOpt2: '£700K - £800K',
    valueOpt3: '£800K - £900K',
    valueOpt4: '£1M - £1.5M',
    valueOpt5: '£1.5M - £2M',
    valueOpt6: '£2M+'
  },
  'zh': {
    heading1: `想了解多一点？`,
    prefix1: `必需全部填写`,
    subheading1: `自我介绍`,
    subheading2: `物业選项`,
    titleFieldLabel: `稱号`,
    titleOptions: [
      { label: '先生', value: 'Mr' },
      { label: '女士', value: 'Ms' }
    ],
    nameFieldLabel: `名字`,
    surnameFieldLabel: `姓氏`,
    emailAddressFieldLabel: `电邮`,
    phoneFieldLabel: `电话号码`,
    countryFieldLabel: `居住地区`,
    propertyValueFieldLabel: `楼价 （英镑）`,
    bedroomsFieldLabel: `房间数`,
    bedroomOptSuite: `开放式`,
    bedroomOpt1Bed: `一房户`,
    bedroomOpt2Bed: `二 房户`,
    bedroomOpt3Bed: `三房户`,
    bedroomOptPenthouse: `覆式户`,
    submitLabel: `提交`,
    successTitle: `登记成功！`,
    successMessage: `感谢您的注册，我们会尽快与您联络`,
    errorTitle: `登记失败`,
    errorMessage: `輸入錯誤，请重新登记`,
    valueOpt1: '少於70万英镑',
    valueOpt2: '70万 – 80万英镑',
    valueOpt3: '80万 – 90万英镑',
    valueOpt4: '100万 – 150万英镑',
    valueOpt5: '150万 – 200万英镑',
    valueOpt6: '200万英镑以上'
  }
});

const initialState = {
  title: '',
  name: '',
  surname: '',
  email: '',
  phone: '',
  country: '',
  budget: '',
  bedrooms: {},
  errors: {},
  sending: false,
  success: undefined
}

const valueOptions = [{
  label: t.valueOpt1,
  value: '<£700K'
}, {
  label: t.valueOpt2,
  value: '£700K - £800K'
}, {
  label: t.valueOpt3,
  value: '£800K - £900K'
}, {
  label: t.valueOpt4,
  value: '£1M - £1.5M'
}, {
  label: t.valueOpt5,
  value: '£1.5M - £2M'
}, {
  label: t.valueOpt6,
  value: '£2M+'
}]

const bedroomOptions = [{
  label: t.bedroomOptSuite,
  value: 'suite',
  icon: 'icon-suite',
},{
  label: t.bedroomOpt1Bed,
  value: '1 bed',
  icon: 'icon-1bed',
},{
  label: t.bedroomOpt2Bed,
  value: '2 bed',
  icon: 'icon-2bed',
},{
  label: t.bedroomOpt3Bed,
  value: '3 bed',
  icon: 'icon-3bed',
},{
  label: t.bedroomOptPenthouse,
  value: 'penthouse',
  icon: 'icon-penthouse',
}]

/**
 * @function validateNotEmpty
 * Validate a value to check that it's not null|undefined|''|{}|[]
 * @param {Mixed} value
 */
const validateNotEmpty = (value) => !isNil(value) && !isEmpty(value)

class FormCard extends Component {
  state = {
    ...initialState
  }

  _onTextInputFocus = ({ target: { name } }) => {
    const { errors } = this.state
    this.setState({ errors: { ...errors, [name]: false } })
  }

  _onTextInputChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  _onSelectFocus = ({ name }) => {
    const { errors } = this.state
    this.setState({ errors: { ...errors, [name]: false } })
  }

  _onSelectChange = ({ name, value }) => {
    this.setState({ [name]: value })
  }

  _onMultiSelectChange = ({ name, value }) => {
    let values = pathOr({}, [name], this.state)
    const isSelected = pathOr(false, [value], values)
    if (isSelected) delete values[value]
    if (!isSelected) values = mergeRight(values, { [value]: true })
    this.setState({ [name]: values })
  }

  _validate = () => {
    const { title, name, surname, email, phone, country, budget, bedrooms } = this.state
    const errors = {}
    if (!validateNotEmpty(title)) errors['title'] = true
    if (!validateNotEmpty(name)) errors['name'] = true
    if (!validateNotEmpty(surname)) errors['surname'] = true
    if (!validateNotEmpty(email)) errors['email'] = true
    if (!validateNotEmpty(phone)) errors['phone'] = true
    if (!validateNotEmpty(country)) errors['country'] = true
    if (!validateNotEmpty(budget)) errors['budget'] = true
    if (!validateNotEmpty(bedrooms)) errors['bedrooms'] = true
    return errors
  }

  _onSubmit = () => {
    const _success = () => {
      this.setState({ ...initialState, success: true })
    }
    const _error = () => {
      this.setState({ sending: false, success: false })
    }
    this.setState({ errors: this._validate() }, () => {
      const { errors } = this.state
      if (isEmpty(errors)) {
        this.setState({ sending: true })
        const email = pathOr(undefined, ['email'], this.state)
        const values = this._getMailchimpFieldValuesFromState()
        if (!email) return undefined
        addToMailchimp(email, values)
          .then(response => _success())
          .catch(error => _error())
      }
    })
  }

  _getError = (name) => pathOr(false, [name], this.state.errors)

  _getMailchimpFieldValuesFromState() {
    const { title: TITLE, name: FNAME, surname: LNAME, phone: PHONE, country: COUNTRY, budget: BUDGET, bedrooms } = this.state
    return { TITLE, FNAME, LNAME, PHONE, COUNTRY, BUDGET, BEDROOMS: join(',', keys(bedrooms)) }
  }

  _renderBedroomItems() {
    const { bedrooms } = this.state
    return bedroomOptions.map(option => (
      <BedroomItem
        key={option.value}
        selected={pathOr(false, [option.value], bedrooms)}
        error={this._getError('bedrooms')}
        onMouseDown={() => this._onSelectFocus({ name: 'bedrooms' })}
        onClick={() => this._onMultiSelectChange({ ...option, name: 'bedrooms' })}>
        <i className={option.icon} /><br/>
        <span>{option.label}</span>
      </BedroomItem>
    ))
  }

  _renderSubmitButton() {
    const { sending } = this.state
    return (
      <Button block disabled={sending} onClick={this._onSubmit}>
        {t.submitLabel}
      </Button>
    );
  }

  _renderMessage() {
    const { success } = this.state
    const _renderMessage = (title, message) => {
      setTimeout(() => this.setState({ success: undefined }), 5000)
      return (
        <Message>
          <MessageBody>
            <h2>{title}</h2>
            <span>{message}</span>
          </MessageBody>
        </Message>
      )
    }
    switch (success) {
      case true: return _renderMessage(t.successTitle, t.successMessage)
      case false: return _renderMessage(t.errorTitle, t.errorMessage)
      default: return null
    }
  }

  render() {
    const { title, name, surname, email, phone, country, budget } = this.state;
    return (
      <Card>
        <Top>
          <h2>{t.heading1}</h2>
          <span>{t.prefix1}</span>
        </Top>
        <Content>
          <h6>{t.subheading1}</h6>
          <FormWrapper>
            <Select
              options={t.titleOptions}
              placeholder={t.titleFieldLabel}
              name="title"
              error={this._getError('title')}
              value={title}
              onFocus={() => this._onSelectFocus({ name: 'title' })}
              onChange={(option) => this._onSelectChange({ ...option, name: 'title' })}
            />
            <Row>
              <Col>
                <Input
                  type="text"
                  placeholder={t.nameFieldLabel}
                  name="name"
                  value={name}
                  error={this._getError('name')}
                  onFocus={this._onTextInputFocus}
                  onChange={this._onTextInputChange}
                />
              </Col>
              <Col>
                <Input
                  type="text"
                  placeholder={t.surnameFieldLabel}
                  name="surname"
                  value={surname}
                  error={this._getError('surname')}
                  onFocus={this._onTextInputFocus}
                  onChange={this._onTextInputChange}
                />
              </Col>
            </Row>
            <Input
              type="email"
              placeholder={t.emailAddressFieldLabel}
              name="email"
              value={email}
              error={this._getError('email')}
              onFocus={this._onTextInputFocus}
              onChange={this._onTextInputChange}
            />
            <Input
              type="text"
              placeholder={t.phoneFieldLabel}
              name="phone"
              value={phone}
              error={this._getError('phone')}
              onFocus={this._onTextInputFocus}
              onChange={this._onTextInputChange}
            />
            <Input
              type="text"
              placeholder={t.countryFieldLabel}
              name="country"
              value={country}
              error={this._getError('country')}
              onFocus={this._onTextInputFocus}
              onChange={this._onTextInputChange}
            />
          </FormWrapper>
          <h6>{t.subheading2}</h6>
          <FormWrapper>
            <Select
              options={valueOptions}
              placeholder={t.propertyValueFieldLabel}
              error={this._getError('budget')}
              value={budget}
              onFocus={() => this._onSelectFocus({ name: 'budget' })}
              onChange={(option) => this._onSelectChange({ ...option, name: 'budget' })}
            />
            <BedroomInputLabel>{t.bedroomsFieldLabel}</BedroomInputLabel>
            <BedroomGroup>
              {this._renderBedroomItems()}
            </BedroomGroup>
          </FormWrapper>
          {this._renderSubmitButton()}
        </Content>
        {this._renderMessage()}
      </Card>
    );
  }
}

export default () => (
  <PageLayout hideFooter restrictHeight={false}>
    <SEO title="Register Your Interest" />
    <Wrapper>
      <CloseButton onClick={() => navigate('/')} />
      <FormCard />
    </Wrapper>
  </PageLayout>
)
