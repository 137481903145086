import styled from 'styled-components'
import { BigCloseButtonTopRight } from 'components/BigCloseButton'
import Color from 'color'
import { colors, media } from 'styles'
import { withPrefix } from 'gatsby'

export const Wrapper = styled.div`
  position: relative;

  ${media.desktop`
    background: url(${withPrefix(`form-bg.png`)}) top center no-repeat;
    background-size: cover;
    height: 100%;
    padding: 24px 0;
  `}

  ${media.tablet`
    padding: 80px 0 38px;
  `}
`

export const CloseButton = BigCloseButtonTopRight

export const Card = styled.div`
  ${media.desktop`
    position: relative;
    background: ${colors.WHITE};
    box-shadow: 0 2px 20px rgba(0,0,0,.09);
    width: 572px;
    max-width: 100%;
    margin: 0 auto;
    padding: 40px 0;
  `}

  ${media.mobile`
    h6 {
      text-align: center;
      margin-top: 50px;
    }
  `}
`

export const Top = styled.div`
  text-align: center;
`

export const Content = styled.div`
  margin-top: 24px;

  ${media.desktop`
    padding: 0 80px;
  `}
`

export const FormWrapper = styled.div`
  margin: 8px 0 33px;
`

export const Row = styled.div`
  display: flex;
  margin: 0 -20px;

  ${media.mobile`
    display: block;
  `}
`

export const Col = styled.div`
  flex: auto;
  padding: 0 20px;
`

export const BedroomGroup = styled.div`
  display: flex;
  padding-left: 1px;

  ${media.mobile`
    flex-wrap: wrap;
  `}
`

const lightYellow = Color(colors.SECONDARY).alpha(.08).string()
const borderErrorColor = Color(colors.ERROR).alpha(.75).string()
export const BedroomItem = styled.button`
  flex: auto;
  border: 1px solid ${colors.BORDER};
  text-align: center;
  font-size: 10px;
  line-height: 10px;
  color: ${colors.PRIMARY};
  background-color: ${colors.WHITE};
  padding-bottom: 10px;
  margin-left: -1px;
  margin-top: -1px;
  cursor: pointer;

  i {
    font-size: 60px;
    color: ${colors.GREY};
  }

  ${props => props.error && `
    border-color: ${borderErrorColor};
  `}

  ${props => props.selected && `
    border-color: ${colors.SECONDARY};
    background: ${lightYellow};
    color: ${colors.SECONDARY};
    z-index: 1;

    i {
      color: inherit;
    }
  `}

  ${media.mobile`
    width: 50% !important;
    flex: none;
    font-size: 14px;
    padding: 15px 0 25px;

    i {
      font-size: 80px;
    }
  `}
`

export const BedroomInputLabel = styled.div`
  margin: 24px 0 10px;
  color: ${colors.GREY};
  font-size: 14px;
  font-weight: 400;
  line-height: 1em;
`

export const Message = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${colors.WHITE};
  z-index: 1;
`

export const MessageBody = styled.div`
  position: absolute;
  top: 50%;
  width: 80%;
  margin: -2em 10% 0 10%;
  text-align: center;
`
