import React from 'react'
import { BaseButton, InternalLink, ExternalLink, Inner } from './styles'

const Button = ({ block, children, ...rest }) => {
  const Component = rest.to
    ? InternalLink
    : rest.href ? ExternalLink : BaseButton
  return (
    <Component {...rest} block={block}>
      <Inner>{children}</Inner>
    </Component>
  )
}

export default Button
