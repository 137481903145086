import styled from 'styled-components'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import Color from 'color'
import { colors } from 'styles'

const borderColor = Color(colors.PRIMARY).alpha(.4).string()
const borderErrorColor = Color(colors.ERROR).alpha(.75).string()
const oddOptionBgColor = Color(colors.BORDER).alpha(.2).string()

export default styled(Dropdown)`
  .Dropdown-control {
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
    border: none;
    border-bottom: 1px solid ${borderColor};
    padding: 1em 0;
    margin-bottom: 8px;
    border-radius: 0;
  }

  .Dropdown-placeholder:not(.is-selected) {
    color: ${colors.GREY};
  }

  .Dropdown-arrow {
    top: 18px;
    right: 2px;
    border-top-color: ${colors.PRIMARY};
  }

  .Dropdown-menu {
    border: none;
    box-shadow: 0 5px 8px rgba(0,0,0,.11);
  }

  .Dropdown-option {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #525658;
    padding: 13px 10px;
    border-left: 2px solid transparent;

    &:hover {
      border-left-color: ${colors.SECONDARY};
      background: #FFF;
    }

    &:nth-child(odd) {
      background: ${oddOptionBgColor};
      &:hover {
        background: ${oddOptionBgColor};
      }
    }
  }

  ${props => props.error && `
    .Dropdown-control {
      border-color: ${borderErrorColor};
    }
  `}

  &.is-open {
    .Dropdown-placeholder {
      color: ${colors.SECONDARY};
    }

    .Dropdown-arrow {
      border-bottom-color: ${colors.SECONDARY};
    }
  }
`
