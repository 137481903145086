import styled from 'styled-components'
import Color from 'color'
import { colors } from 'styles'

const borderColor = Color(colors.PRIMARY).alpha(.4).string()
const borderErrorColor = Color(colors.ERROR).alpha(.75).string()

export default styled.input`
  display: block;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 1em;
  border-bottom: 1px solid ${borderColor};
  padding: 1em 0;
  margin-bottom: 8px;
  border-radius: 0;
  -webkit-appearance: none;

  ${props => props.error && `
    border-color: ${borderErrorColor};
  `}

  &::placeholder {
    color: ${colors.GREY};
  }
`
